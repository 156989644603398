import { eventPageOverviewType } from '@lib/proptypes';
import GridItem from '@molecules/GridItem/GridItem';
import getEventIntervalLabel from '@utils/getEventIntervalLabel';
import PropTypes from 'prop-types';

function EventItem({ item, modifier = 'eventItem' }) {
  const eventDate = item.eventInterval === 'once' ? item.startDate : null;

  const subtitle = getEventIntervalLabel(item);

  return (
    <GridItem
      highlighted={item.highlightedEvent}
      key={item.id}
      title={item.title}
      description={item.overviewText}
      image={item.image}
      date={eventDate}
      dateFormat="iiii d MMMM Y"
      subtitle={subtitle}
      tags={item.categories}
      href={`/agenda${item.slug}`}
      meta={{
        hours: { start: item.startTime, end: item.endTime },
        location: item.location[0]?.title,
      }}
      modifier={modifier}
    />
  );
}

EventItem.propTypes = {
  item: eventPageOverviewType.isRequired,
  modifier: PropTypes.string,
};

export default EventItem;
