import PropTypes from 'prop-types';

import ctaType from './ctaType';

export default PropTypes.shape({
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  recruiter: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        placeholder: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    })
  ),
  firstCta: ctaType,
  secondCta: ctaType,
  extraClasses: PropTypes.string,
  layout: PropTypes.string,
  modifier: PropTypes.string,
}).isRequired;
