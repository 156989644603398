import BannerSection from '@organisms/BannerSection/BannerSection';
import ContactSection from '@organisms/ContactSection/ContactSection';
import ContactTilesSection from '@organisms/ContactTilesSection/ContactTilesSection';
import DownloadSection from '@organisms/DownloadSection/DownloadSection';
import EventsSection from '@organisms/EventsSection/EventsSection';
import FaqSection from '@organisms/FaqSection/FaqSection';
import ImageSection from '@organisms/ImageSection/ImageSection';
import ImageSliderSection from '@organisms/ImageSliderSection/ImageSliderSection';
import JobAlertSection from '@organisms/JobAlertSection/JobAlertSection';
import RelatedNewsSection from '@organisms/RelatedNewsSection/RelatedNewsSection';
import StoriesSection from '@organisms/StoriesSection/StoriesSection';
import TextImageSection from '@organisms/TextImageSection/TextImageSection';
import TextSection from '@organisms/TextSection/TextSection';
import TextVideoSection from '@organisms/TextVideoSection/TextVideoSection';
import TypeformSection from '@organisms/TypeformSection/TypeformSection';
import VideoSection from '@organisms/VideoSection/VideoSection';
import PropTypes from 'prop-types';

const sectionComponents = {
  BannerSection,
  ImageSection,
  TextImageSection,
  TextSection,
  TextVideoSection,
  VideoSection,
  FaqSection,
  RelatedNewsSection,
  JobAlertSection,
  StoriesSection,
  ImageSliderSection,
  DownloadSection,
  EventsSection,
  TypeformSection,
  ContactSection,
  ContactTilesSection,
};

function Sections({ sections }) {
  if (!sections?.length) return null;

  return sections.map((section, index) => {
    const Component = sectionComponents[section?.typename];
    if (!Component) return null;
    // eslint-disable-next-line react/jsx-props-no-spreading, react/no-array-index-key
    return <Component key={`${section.id}-${index}`} {...section} />;
  });
}

Sections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      typename: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};

export default Sections;
