import { storyItemType } from '@lib/proptypes';
import GridItem from '@molecules/GridItem/GridItem';

export default function StoryItem({ item }) {
  return (
    <GridItem
      key={item.id}
      title={item.title}
      image={item.image}
      tags={item.fieldsOfWork}
      href={`/verhalen${item.slug}`}
    />
  );
}

StoryItem.propTypes = {
  item: storyItemType,
};
