import Button from '@atoms/Button/Button';
import RichText from '@atoms/RichText/RichText';
import { ctaType } from '@lib/proptypes';
import ChevronUp from '@svg/chevron-up.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './FaqItem.module.scss';

function FaqItem({ item, id, isOpen, handleAccordion }) {
  const toggleId = `${id}-toggle`;
  const panelId = `${id}-panel`;
  const ctaTitle = item.cta?.title ? item.cta?.title : null;

  let ctaLink;

  if (item.cta?.internalLink[0]?.slug) {
    if (item.cta.internalLink[0].typename === 'HomePage') {
      ctaLink = '/';
    } else {
      ctaLink = item.cta.internalLink[0].slug;
    }
  } else if (item.cta?.externalLink) {
    ctaLink = item.cta.externalLink;
  } else if (item.cta?.file?.url) {
    ctaLink = `${item.cta.file.url}?cd=inline`;
  }

  return (
    <div className={styles.faqItem}>
      <dt className={styles.header}>
        <button
          id={toggleId}
          onClick={handleAccordion}
          className={styles.accordionButton}
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-controls={panelId}
          type="button"
        >
          {item.title}
          <ChevronUp className={styles.chevron} />
        </button>
      </dt>
      <dd
        id={panelId}
        aria-labelledby={toggleId}
        className={classNames(styles.text, !!isOpen && styles.showText)}
      >
        <RichText text={item.text} />

        {ctaLink && ctaTitle && (
          <Button
            href={ctaLink}
            label={ctaTitle}
            extraClasses={styles.ctaButton}
          />
        )}
      </dd>
    </div>
  );
}

FaqItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    cta: ctaType,
  }),
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  handleAccordion: PropTypes.func,
};

export default FaqItem;
