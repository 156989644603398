import { MAXIMUM_RELATED_ITEMS } from '@constants';
import { getRelatedEvents } from '@lib/prepr';
import { formatISO } from 'date-fns';
import { useEffect, useState } from 'react';

import useStore from './useStore';

export default function useEventsSection({
  events = [],
  locations = [],
  highlightedOnly = false,
}) {
  const {
    store: { host, preview },
  } = useStore();

  const [relatedEvents, setRelatedEvents] = useState(
    events
      .filter(event => {
        if (highlightedOnly && !event.highlightedEvent) return false;

        return new Date() <= new Date(event.endDate);
      })
      .splice(0, MAXIMUM_RELATED_ITEMS) || []
  );

  useEffect(() => {
    if (relatedEvents.length) return;

    const fetchData = async () => {
      const data = await getRelatedEvents({
        locations: locations.map(({ id }) => id),
        untilDate: formatISO(new Date()),
        highlightedOnly,
        host,
        preview,
      });

      setRelatedEvents(data.upcomingEvents?.items || []);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    relatedEvents,
  };
}
