import PropTypes from 'prop-types';
import { useState } from 'react';

import FaqItem from './FaqItem';

function FaqList({ faqList, firstQuestionOpen }) {
  const [expandedIndex, setExpandedIndex] = useState(
    firstQuestionOpen ? 0 : -1
  );

  return (
    <dl>
      {faqList.map((item, i) => (
        <FaqItem
          item={item}
          key={item.id}
          id={item.id}
          isOpen={expandedIndex === i}
          handleAccordion={() => setExpandedIndex(i === expandedIndex ? -1 : i)}
        />
      ))}
    </dl>
  );
}

FaqList.propTypes = {
  faqList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  firstQuestionOpen: PropTypes.bool,
};

export default FaqList;
