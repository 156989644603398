import { getRelatedStories } from '@lib/prepr';
import { useEffect, useState } from 'react';

import useStore from './useStore';

export default function useStoriesSection(stories = [], fieldsOfWork = []) {
  const {
    store: { host, preview },
  } = useStore();

  const [relatedStories, setRelatedStories] = useState(stories || []);

  useEffect(() => {
    if (relatedStories.length) return;

    const fetchData = async () => {
      // This check will be removed in the feature when there are more stories across multiple fields or work
      if (!fieldsOfWork.length) {
        return;
      }
      /*
       * TODO: This hook reuses the get related stories call.
       * If we want to seperately fetch the data for both occassions we need to split the call
       * into a SameFieldOfWorks call and a upcomingStories call
       * See: https://esites.atlassian.net/browse/VIT-247
       */
      const data = await getRelatedStories({
        fieldsOfWork: fieldsOfWork.map(({ id }) => id),
        host,
        preview,
      });

      // if (!fieldsOfWork.length) {
      //   if (!data.upcomingStories?.items?.length) return;
      //   setRelatedStories(data.upcomingStories.items);
      //   return;
      // }

      if (!data.sameFieldsOfWorkStories.items) return;
      setRelatedStories(data.sameFieldsOfWorkStories.items);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // return the things we want to surface from this custom hook
  return {
    relatedStories,
  };
}
