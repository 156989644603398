import classNames from 'classnames';
import Image from 'next/legacy/image';
import PropTypes from 'prop-types';

import styles from './BlobImage.module.scss';

/* 
  Blob image is ABSOLUTE positioned
  So make sure it has a relative container with correct ratio
*/
function BlobImage({
  image,
  alt = '',
  sizes,
  caption,
  showImageAsBlobFromLg,
  noBorder,
}) {
  return (
    <figure className={classNames(styles.figure, noBorder && styles.noBorder)}>
      <Image
        src={image.url}
        placeholder="blur"
        blurDataURL={image.placeholder}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        alt={alt}
        sizes={sizes}
        className={classNames(
          styles.image,
          showImageAsBlobFromLg && styles.showImageAsBlobFromLg
        )}
      />
      <svg className={styles.clipPath}>
        <clipPath
          id="clip-path"
          clipPathUnits="objectBoundingBox"
          viewBox="0 0 651 522"
        >
          <path d="M0.237,0.99 L0.237,0.99 C0.182,0.973,0.127,0.933,0.085,0.882 C0.042,0.83,0.011,0.765,0.003,0.698 L0.003,0.698 C-0.005,0.631,0.009,0.561,0.036,0.491 C0.063,0.422,0.104,0.353,0.147,0.287 C0.235,0.158,0.336,0.045,0.472,0.011 C0.608,-0.022,0.779,0.025,0.885,0.16 C0.938,0.227,0.975,0.317,0.992,0.407 C1,0.497,1,0.588,0.972,0.659 C0.941,0.729,0.884,0.779,0.818,0.819 C0.759,0.854,0.692,0.88,0.629,0.906 C0.62,0.909,0.612,0.912,0.604,0.915 C0.592,0.92,0.581,0.925,0.569,0.929 C0.512,0.952,0.46,0.973,0.41,0.987 C0.349,1,0.293,1,0.237,0.99" />
        </clipPath>
      </svg>

      {caption && <figcaption className={styles.caption}>{caption}</figcaption>}
    </figure>
  );
}

BlobImage.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  alt: PropTypes.string,
  sizes: PropTypes.string,
  showImageAsBlobFromLg: PropTypes.bool,
  noBorder: PropTypes.bool,
  caption: PropTypes.string,
};

export default BlobImage;
