import RichText from '@atoms/RichText/RichText';
import { ctaType } from '@lib/proptypes';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './PagepartContent.module.scss';
import PagepartCta from './PagepartCta';

function PagepartContent({
  title,
  subtitle,
  text,
  cta,
  ctaAnimation,
  bottomCta,
  CtaIcon,
  centered,
  extraClasses,
  children,
  isMarkdown,
}) {
  if (!title && !text && !cta?.title && !bottomCta?.title && !children)
    return null;

  return (
    <div
      className={classNames(
        styles.container,
        centered && styles.centered,
        extraClasses
      )}
    >
      {title && <h2 className={styles.title}>{title}</h2>}
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      {text && (
        <RichText text={text} className={styles.text} isMarkdown={isMarkdown} />
      )}
      <PagepartCta
        cta={cta}
        ctaAnimation={ctaAnimation}
        CtaIcon={CtaIcon}
        extraClasses={styles.ctaButton}
      />
      {!!children && <div className={styles.content}>{children}</div>}
      <PagepartCta
        cta={bottomCta}
        CtaIcon={CtaIcon}
        extraClasses={styles.ctaButton}
      />
    </div>
  );
}

PagepartContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  cta: ctaType,
  ctaAnimation: PropTypes.bool,
  bottomCta: ctaType,
  CtaIcon: PropTypes.func,
  extraClasses: PropTypes.string,
  centered: PropTypes.bool,
  isMarkdown: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PagepartContent;
