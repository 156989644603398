import Button from '@atoms/Button/Button';
import { ctaType } from '@lib/proptypes';
import PropTypes from 'prop-types';

function PagepartCta({
  cta,
  CtaIcon,
  extraClasses,
  modifierClass,
  size,
  ctaAnimation = true,
}) {
  if (!cta) return null;

  let ctaLink;
  if (cta.internalLink[0]?.slug) {
    ctaLink = cta.internalLink[0].slug;
  } else if (cta.internalLink[0]?.typename === 'HomePage') {
    ctaLink = '/';
  } else if (cta.externalLink) {
    ctaLink = cta.externalLink;
  } else if (cta.file?.url) {
    ctaLink = `${cta.file.url}?cd=inline`;
  }

  if (!ctaLink || !cta?.title) return null;

  return (
    <Button
      href={ctaLink}
      label={cta.title}
      Icon={CtaIcon}
      extraClasses={extraClasses}
      color={cta.color}
      size={size}
      modifierClass={modifierClass}
      noAnimation={!ctaAnimation}
    />
  );
}

PagepartCta.propTypes = {
  cta: ctaType,
  ctaAnimation: PropTypes.bool,
  CtaIcon: PropTypes.func,
  extraClasses: PropTypes.string,
  modifierClass: PropTypes.string,
  size: PropTypes.string,
};

export default PagepartCta;
