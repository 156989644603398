import 'swiper/css';

import RichText from '@atoms/RichText/RichText';
import { ctaType, imageType, newsType, storyItemType } from '@lib/proptypes';
import PagepartCta from '@molecules/PagepartContent/PagepartCta';
import ChevronLeftIcon from '@svg/chevron-left.svg';
import ChevronRightIcon from '@svg/chevron-right.svg';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './Carousel.module.scss';

const NEXT = 'next';
const PREVIOUS = 'previous';

function Carousel({
  title,
  TitleTag = 'h2',
  text,
  items,
  cta,
  slidesPerView = {
    0: 1.25,
    640: 2.25,
  },
  SlideItem,
  displayScrollBar = false,
}) {
  const prevRef = useRef();
  const nextRef = useRef();

  const [swiperRef, setSwiperRef] = useState();
  const [index, setIndex] = useState(0);

  /* Navigation button handler function */
  const handleNavigation = action => {
    if (action === PREVIOUS) {
      swiperRef.slidePrev();
    } else if (action === NEXT) {
      swiperRef.slideNext();
    }
  };

  /* Keep track of active index */
  const handleSlide = ({ isEnd, isBeginning, activeIndex }) => {
    if (prevRef?.current) {
      prevRef.current.disabled = isBeginning;
    }

    if (nextRef?.current) {
      nextRef.current.disabled = isEnd;
    }

    setIndex(activeIndex);
  };

  return (
    <>
      <div className={styles.container}>
        <div>
          {title && <TitleTag className={styles.title}>{title}</TitleTag>}
          {text && <RichText text={text} className={styles.text} />}
          {displayScrollBar && (
            <div
              className={styles.scrollbar}
              style={{
                // COMMENT: 2.5313 is to make sure that the last bulletpoint is visible and has a border-radius
                '--bar-width': `${2.5313 * (items.length - 1)}rem`,
              }}
              data-scrollbar
            >
              <div
                className={styles.scrollbarProgress}
                style={{
                  left: `${index * 2.625}rem`,
                  width: `2rem`,
                }}
              />
            </div>
          )}
        </div>

        {items.length >= 3 && (
          <div className={styles.controls}>
            <div className={styles.navigationContainer}>
              <button
                type="button"
                onClick={() => handleNavigation(PREVIOUS)}
                className={styles.navigation}
                aria-label="Vorige slide"
                ref={prevRef}
              >
                <ChevronLeftIcon />
              </button>

              <button
                type="button"
                onClick={() => handleNavigation(NEXT)}
                className={styles.navigation}
                aria-label="Volgende slide"
                ref={nextRef}
              >
                <ChevronRightIcon />
              </button>
            </div>
          </div>
        )}
      </div>

      <Swiper
        modules={[Navigation]}
        navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
        onSwiper={setSwiperRef}
        className={styles.swiper}
        spaceBetween={16}
        slidesPerView={slidesPerView[0]}
        initialSlide={0}
        onResize={handleSlide}
        onSlideChange={handleSlide}
        breakpoints={{
          640: {
            spaceBetween: 32,
            slidesPerView: slidesPerView[640],
          },
        }}
      >
        {
          // eslint-disable-next-line react/prop-types
          items.map((item, itemIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`${item.id}-${itemIndex}`}>
              <SlideItem item={item} index={itemIndex} />
            </SwiperSlide>
          ))
        }
      </Swiper>

      <PagepartCta cta={cta} />
    </>
  );
}

Carousel.propTypes = {
  title: PropTypes.string,
  TitleTag: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([newsType, storyItemType, imageType])
  ).isRequired,
  cta: ctaType,
  SlideItem: PropTypes.func,
  displayScrollBar: PropTypes.bool,
  slidesPerView: PropTypes.shape({
    0: PropTypes.number.isRequired,
    640: PropTypes.number.isRequired,
  }),
};

export default Carousel;
