import { newsType } from '@lib/proptypes';
import GridItem from '@molecules/GridItem/GridItem';

function NewsItem({ item }) {
  return (
    <GridItem
      key={item.id}
      title={item.title}
      date={item.publishOn}
      image={item.image}
      tags={item.categories}
      href={`/actueel${item.slug}`}
      modifier="newsItem"
    />
  );
}

NewsItem.propTypes = {
  item: newsType,
};

export default NewsItem;
