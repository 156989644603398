import Button from '@atoms/Button/Button';
import MuxPlayer from '@mux/mux-player-react';
import PlayButtonIcon from '@svg/play-button.svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import styles from './Video.module.scss';

function Video({
  video: { youtubeId, embeddedVideo, alt, ...video } = { alt: '' },
}) {
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideo = () => {
    videoRef.current.play();
    setIsVideoPlaying(!isVideoPlaying);

    if (isVideoPlaying) {
      videoRef.current.pause();
    }
  };

  return (
    <div className={classNames(styles.mediaContainer)}>
      {youtubeId ? (
        <iframe
          className={classNames(styles.media)}
          src={`https://www.youtube.com/embed/${youtubeId}`}
          frameBorder="0"
          allowFullScreen
          title={alt}
        />
      ) : (
        <>
          <MuxPlayer
            playbackId={embeddedVideo?.playbackId}
            ref={videoRef}
            className={classNames(styles.media)}
            style={{ '--controls': isVideoPlaying ? null : 'none' }}
            loading="viewport"
            title={alt || video.name}
            poster={video.thumbnail?.url}
          />
          {!isVideoPlaying && (
            <div className={styles.buttonContainer}>
              <Button
                Icon={PlayButtonIcon}
                modifierClass="playButton"
                extraClasses={styles.button}
                onClick={handleVideo}
                label="Play video"
                hideLabel
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

Video.propTypes = {
  video: PropTypes.shape({
    embeddedVideo: PropTypes.shape({
      playbackId: PropTypes.string,
    }),
    youtubeId: PropTypes.string,
    alt: PropTypes.string,
  }),
};

export default Video;
