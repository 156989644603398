import { imageType } from '@lib/proptypes';
import GridItem from '@molecules/GridItem/GridItem';
import PropTypes from 'prop-types';

function ImageSliderItem({ item }) {
  const image = {
    url: item.url,
    placeholder: item.placeholder,
  };

  return (
    <GridItem
      key={item.id}
      title={item.caption}
      image={image}
      modifier="imageSliderItem"
    />
  );
}

ImageSliderItem.propTypes = {
  caption: PropTypes.string,
  ...imageType,
};

export default ImageSliderItem;
