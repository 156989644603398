import classNames from 'classnames';
import Image from 'next/legacy/image';
import PropTypes from 'prop-types';

import styles from './Figure.module.scss';

function Figure({ image, alt = '', sizes, caption, noBorder }) {
  return (
    <figure className={classNames(styles.figure, noBorder && styles.noBorder)}>
      <Image
        src={image.url}
        placeholder="blur"
        blurDataURL={image.placeholder}
        width={image.width}
        height={image.height}
        alt={alt}
        sizes={sizes}
        className={classNames(styles.image)}
      />

      {caption && <figcaption className={styles.caption}>{caption}</figcaption>}
    </figure>
  );
}

Figure.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    placeholder: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  alt: PropTypes.string,
  sizes: PropTypes.string,
  noBorder: PropTypes.bool,
  caption: PropTypes.string,
};

export default Figure;
